<template>
  <el-dialog :title="`选择${title}`" :visible.sync="dialogVisible" append-to-body>
    <div class="table-transfer">
      <table-col
        :title="`所有${title}`"
        :value="unselectedArr"
        :data-type="dataType"
        ref="left"
        @fetch-data="getUnselectedData"
        :loading="loading"
        :multiple="multiple"
        :total="total"
      ></table-col>
      <div>
        <p>
          <el-button icon="el-icon-arrow-right" circle @click="addHandler"></el-button>
        </p>
        <p>
          <el-button icon="el-icon-arrow-left" circle @click="removeHandler"></el-button>
        </p>
      </div>
      <table-col
        :title="`已选${title}`"
        :value="selectedDataArr"
        :table-type="2"
        :data-type="dataType"
        ref="right"
        :multiple="multiple"
      ></table-col>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import TableCol from "./TableCol";
import { getCourseList, getCouponList } from "@/api/common";

export default {
  name: "TableTransfer",
  components: { TableCol },
  props: {
    value: {
      type: Array,
      default: () => {
        return [];
      },
    },
    limit: {
      type: Number,
      default: 0,
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    producType: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      dataType: 1,
      dialogVisible: false,
      loading: false,
      allData: [],
      unselectedArr: [],
      selectedDataArr: [],
      total: 0,
    };
  },
  computed: {
    title() {
      return this.dataType === 1 ? "优惠券" : "课程";
    },
  },
  methods: {
    handleShow(val) {
      this.dataType = val;
      this.selectedDataArr = this.value;
      this.dialogVisible = true;
      this.getUnselectedData();
    },
    // 获取待选择的数据列表
    async getUnselectedData(params) {
      this.loading = true;
      console.log("getUnselectedData params", params);
      if (this.dataType === 1) {
        if (!params) {
          params = {};
          params["page"] = 1;
          params["size"] = 10;
        }
        const { keyword, ...rest } = params;
        getCouponList({
          ...rest,
          name: keyword,
        }).then((res) => {
          this.loading = false;
          this.unselectedArr = res.data.results;
          this.total = res.data.count;
        });
      } else if (this.dataType === 2) {
        if (!params) {
          params = {};
          params["page"] = 1;
          params["size"] = 10;
        }
        let data = params;
        if (this.producType) {
          data["product_sys_product_type"] = this.producType;
        }
        data["isDisplay"] = "All";
        getCourseList(data).then((res) => {
          this.loading = false;
          this.unselectedArr = res.data.results;
          this.total = res.data.count;
        });
      }
    },
    addHandler() {
      if (!this.disabled) {
        if (this.limit > 0 && this.selectedDataArr.length === this.limit) {
          this.$message({
            message: `最多只允许选择${this.limit}个${this.title}`,
            type: "warning",
          });
          return;
        }

        const noRepeatValue = this.unselectedArr.filter((item1) =>
          this.$refs.left.currentSelectedArr.find(
            (item2) =>
              item1.uuid === item2 && !this.selectedDataArr.find((val1) => val1.uuid === item2)
          )
        );

        this.selectedDataArr = this.selectedDataArr.concat(noRepeatValue);
        this.syncData();
        console.log(this.selectedDataArr);
      }
    },
    removeHandler() {
      if (!this.disabled) {
        const value = this.selectedDataArr.filter(
          (item1) => !this.$refs.right.currentSelectedArr.find((item2) => item1.uuid === item2)
        );
        this.selectedDataArr = value;
        this.syncData();
        console.log(this.selectedDataArr);
      }
    },
    syncData() {
      this.$emit("input", this.selectedDataArr);
      if (this.multiple) {
        this.$refs.left.clearMultipleSelection();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.table-transfer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
::v-deep .el-dialog {
  width: 72% !important;
}
</style>
